import { CommonModule } from '@angular/common';
import {
  Component,
  Inject,
  Input,
  OnChanges,
  signal,
  SimpleChanges,
} from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { TOAST_STYLE_MAP, ToastConfig } from '../../types/toast-config';

@Component({
  selector: 'app-toast',
  standalone: true,
  imports: [CommonModule, TranslocoDirective],
  providers: [{ provide: TOAST_STYLE_MAP, useValue: TOAST_STYLE_MAP }],
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.scss',
})
export class ToastComponent implements OnChanges {
  @Input() config!: ToastConfig | null;

  show = signal<boolean>(false);

  constructor(@Inject(TOAST_STYLE_MAP) public toastStyleMap: any) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['config'].currentValue) {
      this.show.set(true);

      setTimeout(() => this.show.set(false), 5000);
    }
  }
}
