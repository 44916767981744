import { b as bootstrapLazy } from './index-8d04153f.js';
export { s as setNonce } from './index-8d04153f.js';
import { g as globalScripts } from './app-globals-0f993ce5.js';
const defineCustomElements = async (win, options) => {
  if (typeof window === 'undefined') return undefined;
  await globalScripts();
  return bootstrapLazy([["my-component_2", [[1, "my-component", {
    "first": [1],
    "middle": [1],
    "last": [1]
  }], [1, "untitled-button", {
    "label": [1]
  }]]]], options);
};
export { defineCustomElements };

