import { Routes } from '@angular/router';
import { authGuard } from './guards/auth.guard';

export const routes: Routes = [
    {
        path: '',
        canActivate: [authGuard],
        loadComponent: () => import('./pages/dashboard/dashboard.component').then(cmp => cmp.DashboardComponent)
    },
    {
        path: 'revenue',
        canActivate: [authGuard],
        loadComponent: () => import('./pages/revenue/revenue.component').then(cmp => cmp.RevenueComponent)
    },
    {
        path: 'join-us',
        canActivate: [authGuard],
        loadComponent: () => import('./pages/join-us/join-us.component').then(cmp => cmp.JoinUsComponent)
    },
    {
        path: 'something-else',
        canActivate: [authGuard],
        loadComponent: () => import('./pages/something-else/something-else.component').then(cmp => cmp.SomethingElseComponent)
    },
    {
        path: 'not-found',
        canActivate: [authGuard],
        loadComponent: () => import('./pages/not-found/not-found.component').then(cmp => cmp.NotFoundComponent)
    },
    {
        path: '**',
        loadComponent: () => import('./pages/not-found/not-found.component').then(cmp => cmp.NotFoundComponent)
    }
];
