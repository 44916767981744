import { NgFor } from '@angular/common';
import { Component } from '@angular/core';
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { STORAGE_TYPE, StorageService } from './../../services/storage.service';

@Component({
  selector: 'app-language-switcher',
  standalone: true,
  imports: [NgFor, TranslocoDirective],
  templateUrl: './language-switcher.component.html'
})
export class LanguageSwitcherComponent {
  currentLang: string;
  languages: string[];

  constructor(private readonly translocoService: TranslocoService, private readonly storageService: StorageService) {
    this.currentLang = this.translocoService.getDefaultLang();
    const availableLangs = this.translocoService.getAvailableLangs();

    if (Array.isArray(availableLangs) && typeof availableLangs[0] === 'string') {
      this.languages = availableLangs as string[];
    } else {
      this.languages = (availableLangs as { id: string; label: string }[]).map(lang => lang.id);
    }
  }

  onChange(event: Event): void {
    const target = event.target as HTMLSelectElement;
    const langCode = target.value;

    this.translocoService.setActiveLang(langCode);
    this.currentLang = langCode;

    this.storageService.saveItem('LANG', langCode, STORAGE_TYPE.LOCAL_STORAGE);
    window.location.reload();
  }
}