<ng-container *transloco="let t">
  @if (!!config && show()) {
  <div class="toast-wrapper m-4 flex flex-row items-center {{
      config.position || 'top-right'
    }} {{ toastStyleMap[config.type].background }} {{
      toastStyleMap[config.type].border
    }} border-2 border-solid rounded {{ toastStyleMap[config.type].color }}">
    <span class="material-symbols-outlined">
      {{ toastStyleMap[config.type].icon }}
    </span>
    <span>
      {{
      t(config.message) !== config.message
      ? t(config.message)
      : t("http.generic")
      }}
    </span>
  </div>
  }
</ng-container>