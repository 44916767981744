import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { catchError, map, of } from 'rxjs';
import { ApiService } from '../services/api.service';
import { AuthService } from '../services/auth.service';

export const authGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const apiService = inject(ApiService);
  const router = inject(Router);
  const auth = authService.retrieveAuthFromStorage();

  if (auth) {
    authService.updateAuthentication({ isLogged: true, tokens: auth });
    return true;
  } else {
    const params = scrapeQueryParams();
    const code = params['code'];
    if (code) {
      return apiService.getTokenByCode(code).pipe(
        map((res) => {
          authService.saveAuthInStorage(res);
          authService.updateAuthentication({ isLogged: true, tokens: auth });
          router.navigateByUrl('/');
          return true;
        }),
        catchError(() => {
          //authService.goToLogin();
          return of(false);
        })
      );
    } else if (params.length) {
      //gotoerrorpage
      return false;
    } else {
      authService.goToLogin();
      return false;
    }
  }
};

const scrapeQueryParams = () => {
  const insignificantValues = ['null', 'undefined', 'none'];

  const searchParams = new URLSearchParams(window.location.search);
  const result: any = {};

  searchParams.forEach((value, key) => {
    if (value && !insignificantValues.includes(value)) {
      result[key] = value;
    }
  });

  return result;
};
