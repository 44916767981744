import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map, switchMap, throwError } from 'rxjs';
import { ApiService } from './services/api.service';
import { AuthService } from './services/auth.service';
import { Route } from './types/route';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const router = inject(Router);
  const authService = inject(AuthService);
  const apiService = inject(ApiService);

  const THRESHOLD_IN_MINUTES: number = 60 * 30; //30 minutes

  const apiUrl = `${apiService.getBaseUrl()}/api`;
  if (!req.url.includes(apiUrl)) {
    return next(req);
  }

  return authService.retrieveAuthInfo().pipe(
    switchMap((token: any) => {
      if (!token || !token['exp']) {
        authService.logout();
        authService.goToLogin();
      }
      const nowInSeconds = Math.floor(Date.now() / 1000);
      if (nowInSeconds >= (+token['exp'] - THRESHOLD_IN_MINUTES) && nowInSeconds < +token['exp']) {
        return apiService.refreshToken().pipe(
          map((res) => {
            const tokens = { ...authService.retrieveAuthFromStorage(), ...res };
            authService.saveAuthInStorage(tokens);
            authService.updateAuthentication({ isLogged: true, tokens });
            return req;
          }),
          catchError((error: HttpErrorResponse) => {
            authService.updateAuthentication({ isLogged: false });
            authService.logout();
            authService.goToLogin();
            return throwError(() => error);
          }),
          switchMap(() => next(req))
        );
      }
      return next(req);
    }),
    catchError((error: HttpErrorResponse) => {
      if (error.status === 401) {
        authService.logout();
        router.navigate([Route.DASHBOARD]);
      }
      return throwError(() => error);
    })
  );
};
