import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, signal } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { TranslocoDirective } from '@jsverse/transloco';
import { Observable, of, Subscription } from 'rxjs';
import { LanguageSwitcherComponent } from './components/language-switcher/language-switcher.component';
import { LoadingComponent } from './components/loading/loading.component';
import { ToastComponent } from './components/toast/toast.component';
import { AuthService } from './services/auth.service';
import { ToastService } from './services/toast.service';
import { Route } from './types/route';
import { ToastConfig } from './types/toast-config';
import { LoadingService } from './services/loading.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    TranslocoDirective,
    LanguageSwitcherComponent,
    ToastComponent,
    LoadingComponent,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit, OnDestroy {

  isLogged: Observable<boolean> = of(false);
  jwt!: Observable<any | null>;

  subscription: Subscription = new Subscription();
  toastConfig = signal<ToastConfig | null>(null)
  loading = signal<boolean>(false);

  constructor(
    private readonly router: Router,
    private readonly toastService: ToastService,
    private readonly loadingService: LoadingService,
    private readonly authService: AuthService
  ) { }

  ngOnInit() {
    this.isLogged = this.authService.isLogged();
    this.jwt = this.authService.retrieveAuthInfo();

    this.subscription.add(this.toastService.observe().subscribe((config) => this.toastConfig.set(config)));
    this.subscription.add(this.loadingService.observe().subscribe((value) => this.loading.set(value)));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  goToHome() {
    this.router.navigate([Route.DASHBOARD]);
  }
}
