<ng-container *transloco="let t">
  @if(isLogged|async) {
  <main class="h-full">
    <header
      class="flex items-center justify-between bg-primary-color shadow-lg p-4 text-white border-b-8 border-b-primary-light-2">
      <div class="flex items-center cursor-pointer" (click)="goToHome()">
        <img class="w-10 h-10 rounded-full shadow-sm" src="./assets/logo.png" alt="Logo" />
        <span class="pl-4 text-xl font-semibold">Pandorica</span>
      </div>
      <div class="flex items-center gap-6">
        <div class="flex items-center gap-2">
          <span class="google-symbol text-2xl">person</span>
          <span class="text-md-semibold text-light border-r py-2 pr-4">{{
            (jwt | async)?.name
            }}</span>
        </div>
        <app-language-switcher></app-language-switcher>
      </div>
    </header>

    <router-outlet></router-outlet>
    <footer class="bg-primary-color p-4 text-white  border-t-8 border-t-primary-light-2">
      <div class="container mx-auto flex flex-col md:flex-row justify-between items-center">
        <div class="flex items-center space-x-4">
          <img class="w-8" src="./assets/logo.png" alt="Logo Leithá" />
          <span>Pandorica</span>
        </div>
        <div>© 2024 Leithá - Unipol Group</div>
      </div>
    </footer>
  </main>
  <app-toast [config]="toastConfig()" />

  @if(loading()) {
  <app-loading />
  }

  } @else {

  <div
    class="flex flex-col items-center justify-center h-screen m-0 overflow-hidden p-0 w-full bg-gradient-to-b from-[rgba(177,199,233,0.69)] via-[#B1C7E9] to-[#03325B]">
    <div class="z-10 text-center">
      <div class="flex items-center justify-center mb-9">
        <img class="w-24 mr-4" src="./assets/logo.png" alt="Logo" />
        <p class="display-2xl-bold">Pandorica</p>
      </div>
      <div class="display-xs-regular">{{ t("loading") }}...</div>
    </div>
  </div>
  }
</ng-container>